import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Tour from "./pages/Tour";
import About from "./pages/About";
import Contact from "./pages/Contact";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import lifecycle, { componentDidMount } from 'react-pure-lifecycle';
import { useState, useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import TourDetails from "./helpers/TourData";
import { Description, Title } from "@material-ui/icons";
import More_sw from "./pages/More_swamisworld";

export const useGetData = async () => {

  const [tourData, setTourData] = useState(TourDetails);
  await fetch("https://swamiworld.in/db_connect.php")
    .then(response => response.json())
    .then(data => {
      let tourDetails = [];
      data.tours.map((tour) => {
        let tourData = {};
        let itenarary = [];
        let priceDetails = [];
        let includeExculde = [];
        itenarary = data.itinerary.filter((element) => { return element.TourName == tour.TourName }).map((element) => {
          return {
            Title: element.Title, Location: element.Location, Description: element.Description
          }
        });

        includeExculde = data.itinerary.filter((element) => { return element.TourName == tour.TourName }).map((element) => {
          return {
            Title: element.Type, Value: element.Description
          }
        });

        priceDetails = data.itinerary.filter((element) => { return element.TourName == tour.TourName }).map((element) => {
          return {
            "Title": element.Title,
            "Price": element.Price,
            "AirFare": element.AirFare,
            "NetPrice": element.AirFare,
            "Id": element.ID
          }
        });
        tourData = {
          "TourName": tour.TourName,
          "TourDate": tour.TourDate,
          "TourLocation": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243646.90509770525!2d78.2432326337248!3d17.412608641079803!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb99daeaebd2c7%3A0xae93b78392bafbc2!2sHyderabad%2C%20Telangana!5e0!3m2!1sen!2sin!4v1691051075317!5m2!1sen!2sin",
          "Itinerary": itenarary,
          "TourCost": tour.TourCost,
          "TourDays": tour.TourDays,
          "TourId": tour.ID,
          "BannerImage": tour.BannerImage,
          "TileImage": tour.TileImage,
          "Category": tour.Category,
          "TripInlcude": includeExculde.filter((element) => { return element.Type == "Include" }),
          "TripExclude": includeExculde.filter((element) => { return element.Type == "Exclude" }),
          "PriceDetails": priceDetails
        }
        tourDetails.push(tourData);
      });
      setTourData(tourDetails);
      console.log(tourData);

    }
    )
    .catch(error => console.error("Error fetching data:", error));
}

function App() {

  const [tourData, setTourData] = useState(TourDetails);
  const fetchData = async () => {
    const response = await fetch("https://swamiworld.in/db_connect.php");
    const data = await response.json();

    let tourDetails = [];
    data.tours.map((tour) => {
      let tourData = {};
      let itenarary = [];
      let priceDetails = [];
      let includeExculde = [];
      itenarary = data.itinerary.filter((element) => { return element.TourName == tour.TourName }).map((element) => {
        return {
          Title: element.Title, Location: element.Location, Description: element.Description
        }
      });

      includeExculde = data.includeexclude.filter((element) => { return element.TourName == tour.TourName }).map((element) => {
        return {
          Title: element.Type, Value: element.Description
        }
      });

      priceDetails = data.priceDetails.filter((element) => { return element.TourName == tour.TourName }).map((element) => {
        return {
          "Title": element.Title,
          "Price": element.Price,
          "AirFare": element.AirFare,
          "NetPrice": element.AirFare,
          "Id": element.ID
        }
      });
      tourData = {
        "TourName": tour.TourName,
        "TourDate": tour.TourDate,
        "TourLocation": tour.TourLocation,
        "Itinerary": itenarary,
        "TourCost": tour.TourCost,
        "TourDays": tour.TourDays,
        "TourId": tour.ID,
        "BannerImage": tour.BannerImage,
        "TileImage": tour.TileImage,
        "Category": tour.Category,
        "TripInlcude": includeExculde.filter((element) => { return element.Title == "Include" }),
        "TripExclude": includeExculde.filter((element) => { return element.Title == "Exclude" }),
        "PriceDetails": priceDetails,
        "Type":tour.Type
      }
      tourDetails.push(tourData);
    });
    setTourData(tourDetails);
    console.log(tourData);

  }

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="App">
      {tourData && tourData[0] && tourData[0].TourId && tourData[0].TourId != 0 ? <Router>
        <Navbar />
        <Switch>
          <Route path="/" exact  component={()=><Home TourDetails={tourData}/>} />
          <Route path="/tour/:title" render={({match})=><Tour match={match} TourDetails={tourData} ></Tour>} />
          <Route path="/more" exact component={More_sw} />
          <Route path="/about" exact component={About} />
          <Route path="/contact" exact component={Contact} />
        </Switch>
        <Footer />
      </Router> :
        <CircularProgress />}

    </div>
  );
}

export default App;

